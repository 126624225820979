import * as React from "react";
import HeroSection from "../components/HeroSection";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import metaData from "../data/metaData.json";
import data from "../data/home.json";
import AboutSection from "../components/index/AboutSection";
import WhatCanWeDoSection from "../components/index/WhatCanWeDoSection";
import ServiceSection from "../components/index/ServiceSection";
import ContactSection from "../components/index/ContactSection";
import WhyChooseUsSection from "../components/index/WhyChooseUsSection";

const IndexPage = () => {
  return (
    <Layout>
      <SEO {...metaData.home} heroImage={data.heroSection.image}>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "Xramile",
            "url": "https://xramile.com/",
            "logo": "https://xramile.com/logo.png",
            "image": "https://xramile.com/logo.png",
            "email": "info@xramile.com",
            "description": "Xramile is an interdisciplinary Europe-based company specialized in offering business and technology solutions for startup, small, and medium-level companies. We offer everything from agile marketing, branding, and technology solutions to tailored Business, human resources, and financial management services.",
            "address": "Ul. Grzybowska 12/14, lok. 22 Warszawa, Poland 00-132",
            "telephone": "+905418058542",
            "sameAs": [
              "https://www.snapchat.com/add/x-ramile",
              "https://twitter.com/xramile_com",
              "https://www.linkedin.com/company/xramile/",
              "https://www.instagram.com/xramile.business.solutions/"
            ],
            "areaServed": "Saudi Arabia"
          }
        `}</script>
      </SEO>
      <>
        <HeroSection
          title={data.heroSection.title}
          description={data.heroSection.description}
          imgAlt={data.heroSection.imageTitle}
          imgSrc={data.heroSection.image}
        />
        <AboutSection />
        <WhatCanWeDoSection />
        <ServiceSection />
        <WhyChooseUsSection />
        <ContactSection />
      </>
    </Layout>
  );
};

export default IndexPage;
