import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import {
  background_image,
  mid_section_description,
  mid_section_size,
  mid_section_title,
  wrapper,
} from "../../assets/style/GeneralStyleComponent";
import colors from "@variables/colors";
import { MARGIN_5 } from "@variables/margins";
import data from "@data/home.json";
import AppImage from "../AppImage";
import Typography from "../Typography";
import Button from "../Button";

export default function AboutSection() {
  return (
    <Container>
      <Wrapper>
        <AppImage
          src="svedfergver.svg"
          alt="svedfergver"
          css={background_image}
        />
        <Typography
          as="h2"
          content={data.about.title}
          css={about_section_title_style}
        />
        <Typography
          as="p"
          content={data.about.description}
          css={about_section_description_style}
        />
        <Button css={button_style} url="/about-us" title="Learn More" target="_self"/>
      </Wrapper>
    </Container>
  );
}

// about us section style

const Wrapper = styled.div`
  ${wrapper}
`;

const Container = styled.section`
  ${mid_section_size}
  text-align: center;
  background: ${colors.mintGreen[200]};
`;

const about_section_title_style = css`
  ${mid_section_title}
`;

const about_section_description_style = css`
  ${mid_section_description}
  max-width: 75ch;
  margin: 0 auto;
`;

const button_style = css`
  margin: ${MARGIN_5} auto 0;
`;
