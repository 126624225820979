import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import {
  background_image,
  container,
  mid_section_description,
  mid_section_size,
  mid_section_title,
} from "../../assets/style/GeneralStyleComponent";
import AppImage from "../AppImage";
import Typography from "../Typography";
import data from "@data/home.json";

export default function WhatCanWeDoSection() {
  return (
    <Container>
      <Wrapper>
        <AppImage src="bg-arrow.svg" alt="bg-arrow" css={background_image} />
        <Typography
          as="h2"
          content={data.whatCanWeDo.title}
          css={what_can_we_do_title_style}
        />
        <Typography
          as="p"
          content={data.whatCanWeDo.description}
          css={what_can_we_do_description_style}
        />
      </Wrapper>
    </Container>
  );
}

// what we do section style
export const Container = styled.section`
  ${container}
  text-align: center;
`;

export const Wrapper = styled.div`
  ${mid_section_size}
  text-align: center;
`;

export const what_can_we_do_title_style = css`
  ${mid_section_title};
  max-width: 14ch;
  margin: 0 auto;
`;
export const what_can_we_do_description_style = css`
  ${mid_section_description}
  max-width: 75ch;
  margin: 0 auto;
`;
