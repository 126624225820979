import styled from "@emotion/styled";
import React from "react";
import {
  mid_section_description,
  mid_section_size,
  row,
  section_description,
  section_image,
  section_title,
  wrapper,
} from "../../assets/style/GeneralStyleComponent";
import Typography from "../Typography";
import data from "@data/home.json";
import { mdLayout } from "@variables/layout";
import { MARGIN_10 } from "@variables/margins";
import { BORDERRADIUS_5, BORDERWIDTH_1 } from "@variables/border";
import { FONTSIZE_14 } from "@variables/font-sizes";
import { PADDING_5, PADDING_6, PADDING_7 } from "@variables/paddings";
import { css } from "@emotion/react";
import AnimatedImage from "../AnimatedImage";
import ContactButton from "@components/ContactButton";

export default function ContactSection() {
  return (
    <Wrapper>
      <Container>
        <ContactContent>
          <Typography
            as="h2"
            content={data.contact.title}
            css={contact_title_style}
          />
          <Typography
            as="p"
            content={data.contact.description}
            css={contact_description_style}
          />
          <ContactButton>Let's have a coffee!</ContactButton>
        </ContactContent>
        <AnimatedImage
          css={contact_image_style}
          src="last-section.svg"
          alt={data.heroSection.imageTitle}
        />
      </Container>
    </Wrapper>
  );
}

// contact section
const Wrapper = styled.div`
  ${wrapper}
`;
const Container = styled.section`
  ${mid_section_size}
  ${row}
  height: auto;
  border: ${BORDERWIDTH_1} solid #cecece;
  width: 88%;
  border-radius: ${BORDERRADIUS_5};
  padding: ${PADDING_6} ${PADDING_7};
  margin: ${MARGIN_10} auto;
  @media ${mdLayout} {
    padding: ${PADDING_5};
    flex-direction: column-reverse;
  }
`;
const ContactContent = styled.div`
  flex: 1;
  height: 100%;
  margin: auto;
  @media ${mdLayout} {
    text-align: center;
  }
`;

const contact_title_style = css`
  ${FONTSIZE_14}
  ${section_title}
  max-width: 10ch;
  @media ${mdLayout} {
    margin: 0 auto;
  }
`;
const contact_description_style = css`
  ${mid_section_description}
  max-width: 30ch;
  ${section_description}
`;
const contact_image_style = css`
  ${section_image}
  flex: 1;
  max-width: 75ch;
  margin: 0 auto;
`;
